import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import React, { useState } from 'react'
import { Clientes } from '../../types/clientes'
import { ClientCard } from '../ClientCard'
import { LoadingSpinner } from '../LoadingSpinner'
import { deleteDoc, doc } from 'firebase/firestore'
import { db } from '../../config/firebase-config'

export const ClientModal = ({nome, id, cnpj, cpf, titulo, nascimento, gov, codigo_e_cac, codigo_e_social}: Clientes) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [open, setOpen] = useState<boolean>(false);
	const [deletado, setDeletado] = useState<boolean>(false);
	const [deletadoErro, setDeletadoErro] = useState<boolean>(false);
	const [editProp, setEditProp] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

	const onHandleOpenEdit = () => {
		setEditProp(true);
		setOpen(true);
	};

	const onHandleDelete = async (clientId: string) => {
		try {
			setDeletado(true);
			setIsLoading(true);
			const clientDoc = doc(db, 'clientes', clientId);
			await deleteDoc(clientDoc)
			window.location.reload();

		} catch (error) {
			console.error('ClientModal - onHandleDelete - Erro ao deletar cliente', error);
		} finally {
			setIsLoading(false);
		}

	};

	const deleteError = () => {
		setDeletadoErro(true)
	}

	return (
		<div
			className="
				sm:p-2
				sm:m-4
				rounded
				h-4/5
				flex
				flex-col
				justify-between
			"
		>
			{
				isLoading ? <LoadingSpinner /> : <></>
			}

			<Button
				className="
					sm:text-3xl
					hover:text-success
				"
				onClick={handleOpen}
			>
				{nome}
			</Button>

			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="parent-modal-title"
				aria-describedby="parent-modal-description"
				className='h-full'
			>
				<Box sx={{ ...style}} className="bg-white rounded-2xl h-4/5 md:h-4/5 w-11/12 flex flex-col">
					<ClientCard
						id={id}
						nome={nome}
						cnpj={cnpj}
						cpf={cpf}
						titulo={titulo}
						nascimento={nascimento}
						gov={gov}
						codigo_e_cac={codigo_e_cac}
						codigo_e_social={codigo_e_social}
						addNew={false}
						edit={editProp}
					/>
				</Box>
			</Modal>

			<div className='flex flex-col lg:flex-row justify-around m-3'>
				<button
					onClick={onHandleOpenEdit}
					className="
						mb-2
						lg:mb-0
						lg:mr-4
						rounded-lg
						sm:px-4
						sm:py-2
						px-2
						py-1
						bg-secondary
						text-white
						hover:bg-primary
						duration-300
					"
				>
					Editar
				</button>

				<button
					onClick={id ? () => onHandleDelete(id) : deleteError}
					className="
						rounded-lg
						sm:px-4
						sm:py-2
						px-2
						py-1
						bg-danger
						text-white
						hover:bg-white
						hover:text-danger
						duration-300
					"
				>
					Remover
				</button>
			</div>
		</div>
	)
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  p: 1,
};
