// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"
import { getAuth } from "firebase/auth"

const apikey = process.env.REACT_APP_FIREBASE_API_KEY;
const messagingSenderId = process.env.REACT_APP_MESSAGING_SENDER_ID;
const appId = process.env.REACT_APP_APP_ID;
const authDomain = process.env.REACT_APP_APP_AUTH_DOMAIN;
const projectId = process.env.REACT_APP_APP_PROJECT_ID;
const storageBucket = process.env.REACT_APP_APPSTORAGE_BUCKET;
const measurementId = process.env.REACT_APP_APP_MEASUREMENT_ID;

const firebaseConfig = {
  apiKey: apikey,
  authDomain: authDomain,
  projectId: projectId,
  storageBucket: storageBucket,
  messagingSenderId: messagingSenderId,
  appId: appId,
  measurementId: measurementId
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
export const db = getFirestore(app)

export const auth = getAuth(app);
