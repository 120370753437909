import React from 'react'

export const Footer = () => {
	return (
		<>
			<footer id="dark-theme" className='flex flex-col w-full px-4 md:gap-4 p-4 bg-primary'>
				<div className='border border-white mb-1'></div>
				<div className='flex flex-col items-center md:flex-row md:justify-between '>
					<div>
						<p className='text-xs sm:text-left text-white'>Edna de Oliveira Costa</p>
						<p className='text-sm text-white'>&#169; Oliveira & Costa Contabilidade</p>
					</div>
					<div>
						<p className='text-xs text-white'><i>Criado por @_dancosta_</i></p>
					</div>
				</div>
			</footer>
		</>
	)
}
