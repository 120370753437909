import React, { useState } from 'react'
import { Clientes } from '../../types/clientes'
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../config/firebase-config';
import { LoadingSpinner } from '../LoadingSpinner';

export const ClientCard = ({nome, cnpj, cpf, titulo, nascimento, gov, codigo_e_cac, codigo_e_social, addNew, edit, id}: Clientes) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [_nome, setNome] = useState<string>(nome);
	const [_titulo, setTitulo] = useState<number>(titulo);
	const [_nascimento, setNascimento] = useState<string>(nascimento);
	const [_cnpj, setCnpj] = useState<number>(cnpj);
	const [_cpf, setCpf] = useState<number>(cpf);
	const [_gov, setGov] = useState<string>(gov);
	const [_codigo_e_cac, setCodigo_e_cac] = useState<number>(codigo_e_cac);
	const [_codigo_e_social, setCodigo_e_social] = useState<number>(codigo_e_social);

	const clientsCollectionRef = collection(db, "clientes");

	const clientDetails: Clientes = {
		nome: _nome ? _nome : nome,
		titulo: _titulo ? _titulo : 0,
		nascimento: _nascimento ? _nascimento : '',
		cnpj: _cnpj ? _cnpj : 0,
		cpf: _cpf ? _cpf : 0,
		gov: _gov ? _gov : '',
		codigo_e_cac: _codigo_e_cac ? _codigo_e_cac : 0,
		codigo_e_social: _codigo_e_social ? _codigo_e_social : 0,
	}

	const createNewClient = async () => {
		try {
			setIsLoading(true);
			await addDoc(clientsCollectionRef, clientDetails);
			window.location.reload();

		} catch (error) {
			console.error('ClientCard - createNewClient - Erro ao adicionar cliente', error);

		} finally {
			setIsLoading(false);
		}
	}

	const editCurrentUser = async (clientId: string) => {
		try {
			setIsLoading(true);
			const editedField = clientDetails;
			const clientDoc = doc(db, 'clientes', clientId)
			await updateDoc(clientDoc, editedField)
			window.location.reload();

		} catch (error) {
			console.error('ClientCard - editCurrentUser - Erro ao editar cliente', error);

		} finally {
			setIsLoading(false);
		}
	}

	const onSaveButton = (userID: string) => {
		console.log('onSaveButton userID: ' + userID);

		if (addNew) {
			return createNewClient
		}

		editCurrentUser(userID)
	}


	return (
		<div className="rounded-xl overflow-auto relative">
			{
				isLoading ? <LoadingSpinner /> : <></>
			}

			{/* INPUTS */}
			<div className="overflow-hidden text-gray">
				<div className="px-4 py-5 sm:px-6">
					<h3 className="text-lg leading-6 text-primary text-center sm:text-2xl">Dados do Cliente</h3>
					<input
					placeholder="nome..."
					value={_nome}
					onChange={(e) => setNome(e.target.value)}
					className={
						addNew || edit ?
							`
							mt-1
							w-full
							text-center
							border border-secondary
							sm:mt-2
							hover:text-secondary
							mb-2
							sm:mb-0
							text-sm
							sm:col-span-2
							sm:mt-0
							sm:text-4xl
							text-xl
							text-darkGray
							`
						:
							`
							mt-1
							w-full
							text-center
							sm:mt-2
							hover:text-secondary
							mb-2
							sm:mb-0
							text-sm
							sm:col-span-2
							sm:mt-0
							text-darkGray
							sm:text-4xl
							text-xl
							`
					}
				/>
				</div>

				<div className="border-t border-secondary">
					<dl className="">
						<div
							className="
								px-4 py-5
								text-center
								sm:grid
								sm:grid-cols-3
								sm:gap-4
								sm:px-6
								bg-scroll
							"
							>

							<div className="flex flex-col">
								<label className="bg-primary text-white text-sm sm:text-2xl sm:p-1">CNPJ</label>
								<input
									maxLength={14}
									placeholder="cnpj..."
									value={_cnpj}
									onChange={(e: any) => setCnpj(e.target.value)}
									className={
										addNew || edit ?
											`
												mt-1
												text-center
												border border-secondary
												sm:mt-2
												hover:text-secondary
												text-sm
												sm:col-span-2
												sm:mt-0
												sm:text-2xl
											`
										:
											`
												mt-1
												text-center
												sm:mt-2
												hover:text-secondary
												text-sm
												sm:col-span-2
												sm:mt-0
												sm:text-2xl
											`
									}
								/>
							</div>

							<div className="flex flex-col">
								<label className="bg-primary text-white text-sm sm:text-2xl sm:p-1">CPF</label>
								<input
									maxLength={11}
									placeholder="cpf..."
									value={_cpf}
									onChange={(e: any) => setCpf(e.target.value)}
									className={
										addNew || edit ?
											`
												mt-1
												text-center
												border border-secondary
												sm:mt-2
												hover:text-secondary
												text-sm
												sm:col-span-2
												sm:mt-0
												sm:text-2xl
											`
										:
											`
												mt-1
												text-center
												sm:mt-2
												hover:text-secondary
												text-sm
												sm:col-span-2
												sm:mt-0
												sm:text-2xl
											`
									}
								/>
							</div>

							<div className="flex flex-col">
								<label className="bg-primary text-white text-sm sm:text-2xl sm:p-1">Nascimento</label>
								<input
									placeholder="nascimento..."
									value={_nascimento}
									onChange={(e: any) => setNascimento(e.target.value)}
									className={
										addNew || edit ?
											`
												mt-1
												text-center
												border border-secondary
												sm:mt-2
												hover:text-secondary
												text-sm
												sm:col-span-2
												sm:mt-0
												sm:text-2xl
											`
										:
											`
												mt-1
												text-center
												sm:mt-2
												hover:text-secondary
												text-sm
												sm:col-span-2
												sm:mt-0
												sm:text-2xl
											`
									}
								/>
							</div>

							<div className="flex flex-col">
								<label className="bg-primary text-white text-sm sm:text-2xl sm:p-1">GOV</label>
								<input
									placeholder="gov..."
									value={_gov}
									onChange={(e: any) => setGov(e.target.value)}
									className={
										addNew || edit ?
											`
												mt-1
												text-center
												border border-secondary
												sm:mt-2
												hover:text-secondary
												text-sm
												sm:col-span-2
												sm:mt-0
												sm:text-2xl
											`
										:
											`
												mt-1
												text-center
												sm:mt-2
												hover:text-secondary
												text-sm
												sm:col-span-2
												sm:mt-0
												sm:text-2xl
											`
									}
								/>
							</div>

							<div className="flex flex-col">
								<label className="bg-primary text-white text-sm sm:text-2xl sm:p-1">Titulo</label>
								<input
									maxLength={12}
									placeholder="titulo..."
									value={_titulo}
									onChange={(e: any) => setTitulo(e.target.value)}
									className={
										addNew || edit ?
											`
												mt-1
												text-center
												border border-secondary
												sm:mt-2
												hover:text-secondary
												text-sm
												sm:col-span-2
												sm:mt-0
												sm:text-2xl
											`
										:
											`
												mt-1
												text-center
												sm:mt-2
												hover:text-secondary
												text-sm
												sm:col-span-2
												sm:mt-0
												sm:text-2xl
											`
									}
								/>
							</div>

							<div className="flex flex-col">
								<label className="bg-primary text-white text-sm sm:text-2xl sm:p-1">Código & Cac</label>
								<input
									maxLength={12}
									placeholder="código e cac..."
									value={_codigo_e_cac}
									onChange={(e: any) => setCodigo_e_cac(e.target.value)}
									className={
										addNew || edit ?
											`
												mt-1
												text-center
												border border-secondary
												sm:mt-2
												hover:text-secondary
												text-sm
												sm:col-span-2
												sm:mt-0
												sm:text-2xl
											`
										:
											`
												mt-1
												text-center
												sm:mt-2
												hover:text-secondary
												text-sm
												sm:col-span-2
												sm:mt-0
												sm:text-2xl
											`
									}
								/>
							</div>

							<div className="flex flex-col">
								<label className="bg-primary text-white text-sm sm:text-2xl sm:p-1">Código & Social</label>
								<input
									maxLength={12}
									placeholder="código e social..."
									value={_codigo_e_social}
									onChange={(e: any) => setCodigo_e_social(e.target.value)}
									className={
										addNew || edit ?
											`
												mt-1
												text-center
												border border-secondary
												sm:mt-2
												hover:text-secondary
												text-sm
												sm:col-span-2
												sm:mt-0
												sm:text-2xl
											`
										:
											`
												mt-1
												text-center
												sm:mt-2
												hover:text-secondary
												text-sm
												sm:col-span-2
												sm:mt-0
												sm:text-2xl
											`
									}
								/>
							</div>
						</div>
					</dl>
				</div>
			</div>

			{/* SAVING BUTTON */}
			<div className='flex justify-center'>
				{ addNew || edit
					?
						<div className='flex self-center'>
							<button
								onClick={id ? () => onSaveButton(id) : onSaveButton('')}
								className="
									rounded-lg
									sm:px-4
									sm:py-2
									px-2
									py-1
									bg-secondary
									text-white
									hover:bg-white
									hover:text-secondary
									hover:border
									hover:border-secondary
									duration-300
									m-4

								"
							>
								Salvar
							</button>
						</div>
					:
						<></>
				}
			</div>
		</div>
	)
}
