import React from 'react'

export const LoadingSpinner = () => {
	return (
		<div className="spinner-container">
			<div className="loading-spinner">
			</div>
		</div>
	)
}
