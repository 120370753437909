import { useState, useEffect } from 'react'
import { collection, getDocs } from 'firebase/firestore';
import { auth, db } from '../../config/firebase-config';
import { SearchBar } from '../../Components/SearchBar';
import { AddButton } from '../../Components/AddButton';
import moment from 'moment';

moment.locale('pt');

export const Home = () => {
	const [clients, setClients] = useState<any>();
	const [hour, setHour] = useState<any>();
	const [loggedIn, setLoggedIn] = useState<boolean>(false);
	const clientsCollectionRef = collection(db, "clientes");

	const getHourHandler = () => {
		const horario = new Date();
		const horaExata = moment(horario).format(' DD/MM/YYYY - HH:mm');
		setHour(horaExata);
	};

	const getGreeting = () => {
		let greeting: string;
		const hours = new Date().getHours();

		if (hours < 12) {
			greeting = `Bom dia...`
			return greeting;
		} else if ( hours >= 18) {
			greeting = `Boa noite...`
			return greeting;
		} else {
			greeting = `Boa tarde...`
			return greeting;
		}
	}

	useEffect(() => {
		const getClients = async () => {
			const data = await getDocs(clientsCollectionRef)
			const clientsArr = data.docs.map(doc => ({...doc.data(), id: doc.id}));

			setClients(clientsArr);
		}


		getClients();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		setInterval(getHourHandler, 1000);
	}, []);

	useEffect(() => {
		if (auth.currentUser?.email) {
			setLoggedIn(true);
		} else {
			setLoggedIn(false);
		}
	});

	return (
		<div className='w-full'>

			{
				loggedIn

				?
					<div className='h-full mb-auto mt-5 w-full flex flex-col items-center justify-between'>
						<div className='flex flex-col sm:flex-row'>
							<h4 className='text-primary mr-4'> {getGreeting()} </h4>
							<h4 className="text-primary"> {hour} </h4>
						</div>
						<AddButton />
						<SearchBar data={clients}/>
					</div>
				:
					<div className='h-full mb-auto mt-5 w-full flex flex-col items-center justify-center'>
						<div>
							<img
								className='App-logo-start'
								src='https://res.cloudinary.com/dognkye6x/image/upload/v1676155964/oc_logo_ttvro9.png'
								alt='OC Logo'
							/>
						</div>
					</div>

			}

		</div>
	)
}
