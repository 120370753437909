import React from 'react';
import './App.css';
import { Home } from './screen/Home';
import { Footer } from './Components/Footer';
import { NavBar } from './Components/NavBar';

function App() {
  return (
    <div className="App bg-lightGray flex justify-between">
			<NavBar />
			<Home />
			<Footer />
    </div>
  );
}

export default App;
