import { useState } from "react";
import { Clientes } from "../../types/clientes";
import { ClientModal } from "../ClientModal";

interface SearchType{
	data: Clientes[];
}

export const SearchBar = ({data}: SearchType) => {
	const [filterData, setFilterData] = useState<Clientes[]>([])

	const handleFilter = (event: React.FormEvent<HTMLInputElement>) => {
		const searchInput = event.currentTarget.value;

		const newFilter = data.filter((value: Clientes) => {
			return value.nome.toLowerCase().includes(searchInput.toLowerCase());
		})

		if (searchInput === '') {
			setFilterData([]);
		} else {
			setFilterData(newFilter);
		}
	}

	return (
		<div className="w-3/4 sm:w-full m-4 lg:m-6">
			<form>
					<label
						htmlFor="default-search"
						className="mb-2 text-sm font-medium text-gray sr-only dark:text-white"
					>
						Search
					</label>

					<div className="relative">
						<div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none sm:left-1/4">
								<svg aria-hidden="true" className="w-5 h-5 text-primary" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
									<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z">
									</path>
								</svg>
						</div>

						<input
							type="search"
							id="default-search"
							className="
								block w-full sm:w-2/4 sm:flex sm:flex-col sm:mx-auto p-4 pl-10 text-sm text-gray border border-primary rounded-lg
								focus:ring-secondary focus:border-secondary focus:outline-none"
							placeholder="Buscar empresa..."
							onChange={(event) => handleFilter(event)}
							required
						/>
					</div>
			</form>
			{
				filterData.length !== 0 && (
					<div className='flex w-full flex-col flex-wrap sm:flex-row justify-center mt-10 rounded-xl'>
						{
								filterData ?
								filterData.map((client: Clientes) => {
									return (
										<div key={client.id} className='p-4 m-4 bg-white rounded-2xl'>

											<div className='bg-primary rounded-2xl'>
												<h1 className='text-white'>Cliente</h1>
											</div>

											<ClientModal
												id={client.id}
												nome={client.nome}
												cnpj={client.cnpj}
												cpf={client.cpf}
												titulo={client.titulo}
												nascimento={client.nascimento}
												gov={client.gov}
												codigo_e_cac={client.codigo_e_cac}
												codigo_e_social={client.codigo_e_social}
												addNew={false}
											/>
										</div>
									)
								})
								:
								<>
									<div>
										<h2 className='text-primary'>Não existe nenhuma empresa com este nome.</h2>
									</div>
								</>
						}
					</div>
				)
			}

			<div>
				{
					filterData.length === 0 && (
						<div className='flex w-full flex-col flex-wrap sm:flex-row justify-center mt-10 rounded-xl'>
							{
									data ?
									data.map((client: Clientes) => {
										return (
											<div key={client.id} className='p-4 m-4 bg-white rounded-2xl sm:w-3/4 lg:w-1/4'>

												<div className='bg-primary rounded-2xl'>
													<h1 className='text-white'>Cliente</h1>
												</div>

												<ClientModal
													id={client.id}
													nome={client.nome}
													cnpj={client.cnpj}
													cpf={client.cpf}
													titulo={client.titulo}
													nascimento={client.nascimento}
													gov={client.gov}
													codigo_e_cac={client.codigo_e_cac}
													codigo_e_social={client.codigo_e_social}
													addNew={false}
												/>
											</div>
										)
									})
									:
									<>
										<div>
											<h2 className='text-primary'>Não existe nenhuma empresa com este nome.</h2>
										</div>
									</>
							}
						</div>
					)
				}
			</div>


		</div>
	)
}
