import { useState } from "react";
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import { ClientCard } from "../ClientCard";

export const AddButton = () => {
	const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

	return (
		<>
			<div
				className="
					sm:p-2
					sm:m-4
				"
			>
				<button className="sm:text-xl hover:bg-secondary rounded-xl p-4 text-white bg-primary" onClick={handleOpen}>Adicionar Novo</button>
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby="parent-modal-title"
					aria-describedby="parent-modal-description"
					className='h-full'
				>
					<Box sx={{ ...style}} className="bg-white rounded-2xl h-4/5 md:h-4/5 w-11/12 flex flex-col">
					<ClientCard
						id={''}
						nome={''}
						cnpj={0}
						cpf={0}
						titulo={0}
						nascimento={''}
						gov={''}
						codigo_e_cac={0}
						codigo_e_social={0}
						addNew={true}
					/>
					</Box>
				</Modal>
			</div>
		</>
	)
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  p: 1,
};
