import { useEffect, useState } from "react";
import { LoadingSpinner } from "../LoadingSpinner"
import { Box, Modal } from "@mui/material";
import { auth } from "../../config/firebase-config";
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";



export const NavBar = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [open, setOpen] = useState<boolean>(false);
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');
	const [user, setUser] = useState<any>({});
	const [signinError, setSigninError] = useState<boolean>(false);
	const [onDisabled, setDisabled] = useState<boolean>(true);

	const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

	const onSubmitSignIn = async () => {
		try {
			setIsLoading(true);

			const usuario = await signInWithEmailAndPassword(auth, email, password);
			setUser(usuario.user);
			handleClose();
			window.location.reload();

		} catch (error: any) {
			console.error('Erro ao fazer o signIn:', error.message);
			setSigninError(true)
		} finally {
			setIsLoading(false);
		}
	};

	const logout = async () => {
		try {
			setIsLoading(true);
			console.log('logging out');
			await signOut(auth)
			window.location.reload();

		} catch (error: any) {
			console.error('Erro ao fazer o logout:', error.message);
		} finally {
			console.log('logged out');
			setIsLoading(false);
		}
	};

	const handleDisabled = () => {
		if (email !== '' && password !== '') {
			setDisabled(false);
		}
	};

	useEffect(() => {
		handleDisabled();

	});

	useEffect(() => {
		onAuthStateChanged(auth, (currentUser) => {
			setUser(currentUser);
		})
	}, []);

	return (
		<>
			<nav id="dark-theme" className='flex flex-col w-full px-4 md:gap-4 p-4 bg-primary'>
				<div className='flex items-center flex-row justify-between '>
					<div>
						<img
							className='App-logo'
							src='https://res.cloudinary.com/dognkye6x/image/upload/v1676155964/oc_logo_ttvro9.png'
							alt='OC Logo'
						/>
					</div>
					<div className="relative">
						{
							user?.email === undefined
							?
								<button
									className="
										sm:text-2xl
										hover:text-secondary
										text-white
									"
									onClick={handleOpen}
								>
									Entrar
								</button>
							:

							<div className="flex flex-row">
								<p className="text-white mr-4 sm:text-2xl"> {user?.email} </p>
									<button
										className="
											sm:text-2xl
											hover:text-secondary
											text-white
										"
										onClick={logout}
									>
										Sair
									</button>
							</div>
						}


						<Modal
							open={open}
							onClose={handleClose}
							aria-labelledby="parent-modal-title"
							aria-describedby="parent-modal-description"
							className='flex justify-center'
						>
							<Box
								sx={{ ...style}}
								className="
										bg-white
										rounded-2xl
										md:h-4/5
										w-11/12
										lg:w-2/4
										flex
										justify-center
										items-center
										flex-col
										lg:flex-row
									"
							>

								{
									isLoading ? <LoadingSpinner /> : <></>
								}

								<div className="">
									<img
										className='w-full h-full'
										src='https://res.cloudinary.com/dognkye6x/image/upload/v1676238186/oc_logo_blue_glopnk.png'
										alt='OC Logo'
									/>
								</div>

								<div className="flex flex-col w-full items-center justify-center">
									<label
										className="
											mt-1
											text-center
											sm:mt-2
											mb-2
											sm:mb-0
											text-sm
											sm:col-span-2
											sm:mt-0
											sm:text-4xl
											text-xl
											text-primary
										"
									>
										email
									</label>
									<input
										placeholder='email@email.com'
										className="
											mt-1
											text-center
											border border-secondary
											hover:text-secondary
											mb-2
											text-sm
											text-xl
											text-darkGray
											rounded
											w-auto

											sm:mt-2
											sm:mb-0
											sm:col-span-2
											sm:mt-0
											sm:text-4xl
										"
										onChange={(event) => setEmail(event.currentTarget.value)}
										required
										autoCapitalize='none'
									/>

									<label
										className="
										mt-1
										text-center
										sm:mt-2
										mb-2
										sm:mb-0
										text-sm
										sm:col-span-2
										sm:mt-0
										sm:text-4xl
										text-xl
										text-primary
									"
									>
										senha
									</label>
									<input
										placeholder='******'
										required
										className="
										mt-1
										text-center
										border border-secondary
										hover:text-secondary
										mb-2
										text-sm
										text-xl
										text-darkGray
										rounded
										w-auto

										sm:mt-2
										sm:mb-0
										sm:col-span-2
										sm:mt-0
										sm:text-4xl
										"
										type="password"
										onChange={(event) => setPassword(event.currentTarget.value)}
									/>

									<button
										className="
											sm:text-xl
											hover:text-secondary
											text-white
											bg-primary
											hover:bg-secondary
											hover:text-white
											rounded
											w-1/2
											p-4
											mt-4
											mb-4
										"
										disabled={onDisabled}
										onClick={onSubmitSignIn}
									>
										Entrar
									</button>
									<div>
										{
											signinError && (
												<p className="text-danger mb-10">Email ou Senha inválidos.</p>
											)
										}
									</div>
								</div>
							</Box>
						</Modal>
					</div>
				</div>
			</nav>
		</>
	)
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  p: 1,
};
